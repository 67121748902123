import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  Lang,
} from '@bluecurrent/web-component-lib';

import Item from '../Item';
import Controls from './Controls';

export default function EditList() {
  const { _ } = Lang.useTranslation();
  const dispatch = useDispatch();

  const GROUPS = useSelector((state) => state.Groups.groups);

  const [isEditMode, setEditMode] = React.useState(false);

  const onPressGroup = (item) => {
    if (isEditMode) {
      dispatch({
        type: 'OVERLAY',
        payload: {
          layout: 'groupsEdit',
          display: true,
          title: _('group.edit', { ns: 'ui' }),
          data: item,
        },
      });
    }
  };

  return (
    <>
      <Controls
        isEditMode={isEditMode}
        setEditMode={() => setEditMode(!isEditMode)}
      />
      <div
        style={{
          paddingLeft: 15,
          paddingRight: 15,
          paddingTop: 15,
        }}
      >
        {
          GROUPS.map((item) => (
            <Item
              key={item.group.id}
              name={item.group.name}
              icon={item.group.icon}
              items={item.group.chargepoints}
              onClick={() => onPressGroup(item.group)}
              isEditMode={isEditMode}
            />
          ))
        }
      </div>
    </>
  );
}
