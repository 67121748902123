import * as React from 'react';
import { useSelector } from 'react-redux';
import {
  Outlet, useSearchParams,
} from 'react-router-dom';
import { motion } from 'framer-motion';
import mapboxgl from 'mapbox-gl';

import {
  Breakpoint,
  Input,
  ConfirmDialog,
  useDimensions,
  Lang,
  Button,
  Colors,
  Text,
} from '@bluecurrent/web-component-lib';

/* eslint-disable-next-line import/no-named-as-default, import/no-named-as-default-member */
import useStorage from '../hooks/useStorage';

import Groups from '../components/Sections/Groups/index';
import Modal from '../components/Sections/Modal';

import getLayout from '../components/Sections/ChargePoints/Layout/getLayout';
import ChargePointLayout from '../components/Sections/ChargePoints/Layout';
import getFilter from '../components/Sections/ChargePoints/Filter/getFilter';
import selectFilter from '../components/Sections/ChargePoints/Filter/selectFilter';
import useTextSearch from '../hooks/useTextSearch';

export default function ChargePoints() {
  const size = useDimensions();
  const { _ } = Lang.useTranslation();
  const storage = useStorage();
  const textSearch = useTextSearch();
  const [searchParams, setSearchParams] = useSearchParams();

  const groups = useSelector((state) => state.Groups.groups);
  const chargePoints = useSelector((state) => state.ChargePoints);
  const getFilterTypes = getFilter.map((f) => f.type);
  const defaultFilters = searchParams.get('filter')
    ? searchParams.get('filter').split(',').filter((i) => getFilterTypes.includes(i))
    : storage.getItem('chargepoints_filter')
      ? storage.getItem('chargepoints_filter').split(',').filter((i) => getFilterTypes.includes(i))
      : ['all'];

  const layoutWrapperRef = React.useRef(0);

  const [width, setWidth] = React.useState('100%');

  const [modal, setModal] = React.useState(false);
  const [rebootDialog, setRebootDialog] = React.useState(false);
  const [deleteGroupDialog, setDeleteGroupDialog] = React.useState(false);

  const rebootFuncRef = React.useRef();
  const deleteGroupFuncRef = React.useRef();

  const [group, setGroup] = React.useState(null);
  const [evseGroup, setEvseGroup] = React.useState(null);

  const [layout, setLayout] = React.useState('list');
  const [map, setMap] = React.useState(false);
  const [filter, setFilter] = React.useState(defaultFilters);

  const [search, setSearch] = React.useState('');

  const activeStatuses = chargePoints.map((i) => i.activity);
  const activeAmount = chargePoints.filter(
    (item) => (
      (filter.includes('all') || filter.includes(item.activity))
        && textSearch(search, [
          'evse_id',
          'name',
          'model_type',
          'chargepoint_type',
          'location.street',
          'location.city',
          'location.country',
          'location.zipcode',
        ])(item)
        && (group === null || evseGroup.includes(item.evse_id))
    ) && (layout === 'map' ? item.location.x_coord && item.location.y_coord : true),
  ).length;

  const getDimensions = () => {
    if (layoutWrapperRef.current.clientWidth !== null) {
      setWidth(layoutWrapperRef.current.clientWidth);
    }
  };

  React.useEffect(() => {
    getDimensions();
  }, [width]);

  const getLocalStorage = () => {
    if (storage.getItem('cookies_accepted') === 'true') setMap(true);
  };

  React.useEffect(() => {
    window.addEventListener('resize', getDimensions);
    window.addEventListener('storage', getLocalStorage);

    return () => {
      window.removeEventListener('resize', getDimensions);
      window.removeEventListener('storage', getLocalStorage);
    };
  }, []);

  return (
    <>
      <motion.div
        ref={layoutWrapperRef}
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
        }}
        transition={{
          duration: 1,
        }}
        style={{
          display: 'flex',
          flexDirection: 'column',
          paddingLeft: 10,
          paddingRight: 10,
          height: size.width < Breakpoint.md ? 'fit-content' : 'auto',
          width: '100%',
        }}
      >
        {/* Top Bar */}
        <div
          style={{
            display: 'flex',
            flexDirection: size.width < Breakpoint.sm ? 'column' : 'row',
            justifyContent: 'flex-start',
            width: size.width < Breakpoint.sm ? 'calc(100% - 25px)' : 'calc(100% - 95px)',
            paddingTop: '15px',
            position: 'fixed',
            zIndex: 2,
            backgroundColor: Colors.WHITE,
            columnGap: '15px',
          }}
        >
          <div
            style={{
              display: 'flex',
              columnGap: '5px',
            }}
          >
            {size.width < 1200 && (
            <div
              style={{
                width: 'auto',
              }}
            >
              <Button
                type="icon"
                colorScheme="white"
                icon={{
                  name: 'Folder',
                  width: 25,
                  height: 25,
                  color: Colors.GREY,
                  iconSet: 'FA',
                }}
                borderRadius={7}
                onClick={() => {
                  setModal(true);
                }}
              />
            </div>
            )}
            <div
              style={{
                width: size.width < 1200 ? '100%' : 400,
                display: 'flex',
                flexDirection: 'row',
                marginBottom: 15,
              }}
            >
              <Input
                type="search"
                placeholder={_('search', { ns: 'ui' })}
                borderRadius={7}
                onChange={(event) => setSearch(event.target.value)}
                value={search}
              />
            </div>
          </div>
          <div
            style={{
              display: 'flex',
            }}
          >
            <div
              style={{
                width: 'auto',
              }}
            >
              <Input
                type="dropdown"
                id="chargepoints-layout"
                icon={getLayout.filter((e) => e.type === layout)[0].icon}
                selected={false}
                text=""
                items={
                  getLayout.map((event) => (
                    {
                      ...event,
                      type: 'link',
                      id: event.type,
                      text: Array.isArray(event.title) ? _(...event.title) : event.title,
                      onClick: () => {
                        setLayout(event.type);
                        storage.setItem('charge_point_layout', event.type);
                      },
                      disabled: event.type === 'map' ? map ? !map : (!mapboxgl.supported() || storage.getItem('cookies_accepted') !== 'true') : false,
                    }
                  ))
                }
              />
            </div>
            <div
              style={{
                width: 'auto',
                marginLeft: size.width < 1200 ? 5 : 15,
                marginRight: size.width < 1200 ? 5 : 15,
              }}
            >
              <Input
                type="dropdown"
                id="chargepoints-filter"
                text=""
                translateX={size.width < Breakpoint.fd ? 'calc(155px - 100%)' : false}
                icon={{
                  name: 'Filter',
                  height: 20,
                  width: 20,
                  color: Colors.GREY,
                  iconSet: 'Feather',
                }}
                items={
                  getFilter.map((f) => ({
                    ...f,
                    selected: filter.includes(f.type),
                    type: 'checkbox',
                    id: f.type,
                    text: Array.isArray(f.title) ? _(...f.title) : f.title,
                    disabled: f.type !== 'all' && !activeStatuses.includes(f.type),
                    onClick: () => {
                      const newFilter = selectFilter(f.type, filter, activeStatuses);
                      setFilter(newFilter);
                      setSearchParams({ filter: newFilter.join(',') });
                    },
                  }))
                }
              />
            </div>
          </div>
        </div>
        <div
          style={{
            marginTop: size.width < Breakpoint.sm ? '135px' : '80px',
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
          }}
        >
          {/* Group div */}
          {size.width >= 1200 && (
            <div
              style={{
                maxWidth: 400,
                width: 400,
                position: 'fixed',
              }}
            >
              <Groups
                selected={group}
                onClickGroup={(id, name, evses) => {
                  setGroup(id);
                  setEvseGroup(evses);
                }}
                setEdit={() => setModal(true)}
              />
            </div>
          )}

          {/* Chargepoint List */}
          <div
            style={{
              width: '100%',
              height: 'auto',
              minHeight: 'fit-content',
              position: 'relative',
              zIndex: 0,
              display: 'flex',
              flexDirection: 'column',
              marginLeft: size.width >= 1200 ? 415 : 0,
            }}
          >
            <div
              style={{
                position: 'fixed',
                width: size.width >= 1840
                  ? '1165px'
                  : size.width >= 1700 ? 'calc(100% - 690px)'
                    : size.width >= 1670 ? '1167px'
                      : size.width >= 1200 ? 'calc(100% - 520px)'
                        : size.width >= 768 ? 'calc(100% - 105px)'
                          : 'calc(100% - 35px)',
                backgroundColor: Colors.WHITE,
                borderBottom: `solid 2px ${Colors.MEDIUM_WHITE}`,
                paddingTop: '3px',
                paddingBottom: '2px',
                zIndex: 1,
              }}
            >
              <Text
                fontSize="0.9em"
                color={Colors.DARK_GREY}
              >
                {_('showingAmountChargePoint', { ns: 'ui', amount: activeAmount, total: chargePoints.length })}
              </Text>
            </div>
            <div
              style={{
                width: '100%',
                marginTop: '20px',
              }}
            >
              <ChargePointLayout
                layout={layout}
                width={width}
                filter={filter}
                group={evseGroup}
                search={search}
              />
            </div>
          </div>
        </div>
      </motion.div>
      <Modal
        visible={modal}
        title={_('group.groups', { ns: 'ui' })}
        onClose={() => setModal(false)}
        type="groupsListEdit"
        onClickGroup={(id, name, evses) => {
          setGroup(id);
          setEvseGroup(evses);
        }}
        data={groups}
        useDeleteGroupDialog={[deleteGroupDialog, setDeleteGroupDialog]}
        setDeleteGroupFunc={(func) => {
          deleteGroupFuncRef.current = func;
        }}
      />
      <Outlet
        context={{
          useRebootDialog: [rebootDialog, setRebootDialog],
          setRebootFunc: (func) => {
            rebootFuncRef.current = func;
          },
        }}
      />
      {/* Reboot dialog */}
      <ConfirmDialog
        visible={rebootDialog}
        title={_('actions.reboot', { ns: 'chargepoint' })}
        description={_('actions.rebootWarning', { ns: 'chargepoint' })}
        accept={{
          text: _('actions.reboot', { ns: 'chargepoint' }),
          onClick: () => {
            setRebootDialog(false);
            rebootFuncRef.current();
          },
          colorScheme: 'red',
        }}
        cancel={{
          onClick: () => setRebootDialog(false),
        }}
      />
      {/* Delete group dialog */}
      <ConfirmDialog
        visible={deleteGroupDialog}
        title={_('delete', { ns: 'ui' })}
        description={_('group.deleteConfirmDialog', { ns: 'ui' })}
        accept={{
          text: _('delete', { ns: 'ui' }),
          onClick: () => {
            setDeleteGroupDialog(false);
            deleteGroupFuncRef.current(deleteGroupDialog);
          },
          colorScheme: 'red',
        }}
        cancel={{
          onClick: () => setDeleteGroupDialog(false),
        }}
      />
    </>
  );
}
