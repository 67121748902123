import * as React from 'react';
import { useSelector } from 'react-redux';

import {
  Lang,
  Colors,

  useDimensions,
} from '@bluecurrent/web-component-lib';

import Item from './Item';

export default function GroupsList({
  onClickGroup,
  onClose,
}) {
  const { _ } = Lang.useTranslation();
  const size = useDimensions();

  const GROUPS = useSelector((state) => state.Groups.groups);

  const onPressAllChargePoints = () => {
    onClickGroup(null, null, null);
    onClose();
  };

  const onPressGroup = (item) => {
    onClickGroup(item.group.id, item.group.name, item.group.chargepoints);
    onClose();
  };

  return (
    <div
      style={{
        paddingLeft: 15,
        paddingRight: 15,
        paddingTop: 15,
      }}
    >
      {
        size.width <= 1200 && (
          <Item
            name={_('allChargePoints', { ns: 'ui' })}
            icon={{
              name: 'UMOVE',
              height: 27,
              width: 27,
              color: Colors.GREY,
              iconSet: 'BlueCurrent',
            }}
            onClick={() => onPressAllChargePoints()}
          />
        )
      }
      {
        GROUPS.map((item) => (
          <Item
            key={item.group.id}
            name={item.group.name}
            icon={item.group.icon}
            items={item.group.chargepoints}
            onClick={() => onPressGroup(item)}
          />
        ))
      }
    </div>
  );
}
