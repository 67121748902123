import * as React from 'react';

import {
  Colors,

  OverlaySearch,
} from '@bluecurrent/web-component-lib';

export default function ChargePoints({
  isVisible,
  items,
  setItems,
  chargePoints,
  onClose,
}) {
  const onPress = (item) => {
    if (items.includes(item.evse_id)) {
      setItems((previous) => previous.filter((x) => x !== item.evse_id));
    } else {
      setItems((previous) => [...previous, item.evse_id]);
    }
  };

  return isVisible && (
    <OverlaySearch
      data={
        chargePoints.map((item) => ({
          name: item.name,
          serial: item.serial,
          type: 'chargepoint',
          selected: items.includes(item.evse_id),
          icon: {
            name: 'UMOVE',
            height: 50,
            width: 50,
            color: Colors.GREY,
            iconSet: 'BlueCurrent',
          },
          onClick: () => onPress(item),
        }))
      }
      onClose={onClose}
      selectAll={
        items.length === chargePoints.length
          ? false
          : () => setItems(chargePoints.map((item) => item.evse_id))
      }
      deselectAll={
        items.length === chargePoints.length
          ? () => setItems([])
          : false
      }
    />
  );
}
