import * as React from 'react';

import {
  Modal,
  OverlaySearch,
} from '@bluecurrent/web-component-lib';

import GroupsCreate from './Group/Create';
import GroupsEdit from './Group/Edit';
import GroupsListEdit from './Group/List/Edit';
import GroupsList from './Group/List';
import ChargePointSettings from './ChargePointSettings';
import DeclarationForm from './DeclarationForm';
import AreYouSure from './AreYouSure/index';
import Language from './Language';
import LinkedChargeCards from './LinkedChargeCards';
import ChargeCardSettings from './ChargeCardSettings';
import DefaultChargeCard from './DefaultChargeCard';
import LinkedChargePoints from './LinkedChargePoints';
import Transaction from './Transaction';
import ExcludedOnChargePoints from './ExcludedOnChargePoints';
import ProfiledCharging from './ProfiledCharging';

export default function ModalIndex(props) {
  const [title, setTitle] = React.useState(props.title);
  const [subTitle, setSubTitle] = React.useState(props.subTitle);

  React.useEffect(() => {
    document.querySelector('body').style.overflow = props.visible ? 'hidden' : 'auto';
  });

  /*
  * The title and subtitle are in different Effect hooks
  * Because we can overwrite from within or from outside the modal
  * We don't want to overwrite the title if only the subtitle changes etc.
  */
  React.useEffect(() => {
    setTitle(props.title);
  }, [props.title]);

  React.useEffect(() => {
    setSubTitle(props.subTitle);
  }, [props.subTitle]);

  const renderContent = () => {
    switch (props.type) {
      case 'overlaySearch':
        /* Generic option for everything that requires a basic search */
        return (
          <OverlaySearch
            data={props.data}
          />
        );
      case 'groupsCreate':
        return (
          <GroupsCreate
            onClose={props.onClose}
            setTitle={setTitle}
            setSubTitle={setSubTitle}
          />
        );
      case 'groupsEdit':
        return (
          <GroupsEdit
            onClose={props.onClose}
            setTitle={setTitle}
            setSubTitle={setSubTitle}
            data={props.data}
          />
        );
      case 'groupsListEdit':
        return (
          <GroupsListEdit
            onClose={props.onClose}
            setTitle={setTitle}
            setSubTitle={setSubTitle}
            data={props.data}
          />
        );
      case 'groupsList':
        return (
          <GroupsList
            onClose={props.onClose}
            setTitle={setTitle}
            setSubTitle={setSubTitle}
            data={props.data}
            onClickGroup={props.onClickGroup}
            useDeleteGroupDialog={props.useDeleteGroupDialog}
            setDeleteGroupFunc={props.setDeleteGroupFunc}
          />
        );
      case 'chargePointSettings':
        return (
          <ChargePointSettings
            serial={props.serial}
            onClose={props.onClose}
            setModalTitle={setTitle}
            error={props.error}
            setModalSubTitle={setSubTitle}
            useRebootDialog={props.useRebootDialog}
            setRebootFunc={props.setRebootFunc}
          />
        );
      case 'declarationForm':
        return (
          <DeclarationForm
            onClose={props.onClose}
          />
        );
      case 'areYouSure':
        return (
          <AreYouSure
            onClose={props.onClose}
            content={props.content}
            onConfirm={props.onConfirm}
            onCancel={props.onCancel}
            error={props.error}
          />
        );
      case 'language':
        return (
          <Language
            onClose={props.onClose}
          />
        );
      case 'linkedChargeCards':
        return (
          <LinkedChargeCards
            onClose={props.onClose}
            data={props.data}
          />
        );
      case 'linkedChargePoints':
        return (
          <LinkedChargePoints
            onClose={props.onClose}
            data={props.data}
            status={props.status}
          />
        );
      case 'chargeCardSettings':
        return (
          <ChargeCardSettings
            onClose={props.onClose}
            data={props.data}
            setTitle={setTitle}
            setSubTitle={setSubTitle}
            useDeleteCardDialog={props.useDeleteCardDialog}
            setDeleteCardFunc={props.setDeleteCardFunc}
          />
        );
      case 'defaultChargeCard':
        return (
          <DefaultChargeCard
            data={props.data}
            onClose={props.onClose}
          />
        );
      case 'transaction':
        return (
          <Transaction
            onClose={props.onClose}
            id={props.data}
          />
        );
      case 'excludedOnChargePoints':
        return (
          <ExcludedOnChargePoints
            onClose={props.onClose}
            id={props.data}
            status={props.status}
          />
        );
      case 'profiledCharging':
        return (
          <ProfiledCharging />
        );
      default:
        return (
          <div />
        );
    }
  };

  return (
    <Modal
      title={title}
      subTitle={subTitle}
      onClose={props.onClose}
      visible={props.visible}
    >
      {
        renderContent()
      }
    </Modal>
  );
}
